<template lang="pug">
.page
  el-card
    .componentContainer
      .item(v-for="(i,n) in bannerList" :key="n" v-bg.cover="i.imageUrl")
        .actionBar
          .btn(@click="handleMoveLeft(n)" v-if="n!==0")
            el-icon
              ArrowLeft
          .btn(@click="handleMoveRight(n)" v-if="n!==bannerList.length-1")
            el-icon
              ArrowRight
          .btn(@click="handleEdit(n)")
            el-icon
              Edit
          .btn(@click="handleDelete(n)")
            el-icon
              Delete
      .item.addButton(@click="handleAdd" v-if="!isMiniprogram")
        el-icon
          Plus
  el-card
    el-button(type="primary" style="width:100%" @click="handleSubmit") 保存
.cover(v-loading="true" element-loading-text="审核中..." v-if="isAudit")
el-dialog(v-model="isShowEditDialog" title="编辑")
  el-form(label-width="6em" :label-position="isMobile?'top':'right'")
    el-form-item(label="图片")
      .bannerItem(v-bg.cover="editData.imageUrl" @click="handlePickImage")
    el-form-item(label="跳转目标")
      .jumpTargetInfo(v-if="jumpTargetInfo.id")
        .type {{jumpTargetInfo.type}}
        img.logo(:src="jumpTargetInfo.logo")
        .name {{jumpTargetInfo.name}}
        .desc {{jumpTargetInfo.desc}}
        .status
          el-tag(v-if="jumpTargetInfo.hideStatus==1" type="info" size="small") 隐藏
          el-tag(v-else type="success" size="small") 显示
    el-form-item(label="选择跳转目标")
      el-button(type="primary" @click="isShowJumpPicker=true") 选择跳转目标
    el-form-item(label="是否显示")
      el-switch(v-model="editData.hideStatus" :inactive-value="1" :active-value="0")
    el-form-item(label="过期时间")
      el-date-picker(v-model="editData.expirationTime" type="datetime" value-format="x")
    el-form-item(label=" ")
      el-button(type="primary" style="width:100%" :disabled="!!errorMessageForEdit" @click="handleSubmitEdit") {{errorMessageForEdit||'保存'}}

el-drawer(v-model="isShowJumpPicker" direction="btt" size="80%")
  el-form(label-width="6em" :label-position="isMobile?'top':'right'")
    el-form-item(label="类型")
      el-radio-group(v-model="editData.linkType")
        el-radio-button(label="PRODUCT") 商品
        el-radio-button(label="STORE") 门店
        //- el-radio-button(label="NONE") 无
    el-form-item(label="跳转目标" v-if="editData.linkType!=='NONE'")
      .productList(v-if="editData.linkType==='PRODUCT'")
        .productItem(v-for="i in productList"  @click="handleClickPickerItem(i.productId)" :class="{isActive:i.productId===currentPickLinkId}")
          img.logo(:src="i.productImageUrl")
          .info
            .name {{i.productName}}
            .desc {{i.salePrice}}
      .storeList(v-if="editData.linkType==='STORE'")
        .storeItem(v-for="i in storeList" @click="handleClickPickerItem(i.id)" :class="{isActive:i.id===currentPickLinkId}")
          img.logo(:src="i.storeLogo")
          .info
            .name {{i.storeTitle}}
            .desc 楼层：{{i.commercialPlazaRegionTitle}}
            .status
              el-tag(v-if="i.hideStatus==1" type="info" size="small") 隐藏
              el-tag(v-else type="success" size="small") 显示
      //- .pagination
      //-   el-pagination(layout="total, prev, pager, next" background v-model:page-size="pagination.size" :pager-count="isMobile?3:5" :page-sizes="[10, 20, 50, 100]" :total="pagination.total" v-model:current-page="pagination.page" @current-change="getList")
  template(#footer)
    div
      el-button(@click="isShowJumpPicker=false") 取消
      el-button(type="primary" @click="handleSubmitPick") 确定
</template>

<script setup>
import { inject, onMounted, computed, ref, watch } from 'vue'
import { Edit, Delete, Plus, ArrowLeft, ArrowRight } from '@element-plus/icons-vue'

import { currentMarketId } from '@/provider/account'
import { isMobile } from '@/provider/sys'
import { pickImageList } from '@/provider/upload'
import { isMiniprogram } from '@/provider/sys'

const emits = defineEmits(['input'])

const { fetch, message, router } = inject('global')

const isAudit = ref(false)
const isShowJumpPicker = ref(false)
const jumpTargetInfo = computed(() => {
  if (!editData.value?.linkType) {
    return {
      type: '未定义'
    }
  } else if (editData.value.linkType === 'NONE') {
    return {
      type: '无跳转'
    }
  } else if (editData.value.linkType === 'PRODUCT') {
    const item = productList.value.find(i => i.productId === editData.value.linkId)
    return {
      type: '商品',
      name: item?.productName,
      desc: `价格：${item?.salePrice || ''}`,
      hideStatus: '',
      id: item?.productId,
      logo: item?.productImageUrl
    }
  } else if (editData.value.linkType === 'STORE') {
    const item = storeList.value.find(i => i.id === editData.value.linkId)
    return {
      type: '门店',
      name: item?.storeTitle,
      desc: `楼层：${item?.commercialPlazaRegionTitle || ''}`,
      hideStatus: item?.hideStatus,
      id: item?.id,
      logo: item?.storeLogo
    }
  } else {
    return {
      type: '未定义'
    }
  }
})

const pagination = ref({
  page: 1,
  size: 1000,
  total: 0
})
const productList = ref([])
function getProductList() {
  fetch
    .get(`/boom-center-search-service/businessAdmin/commercialPlazaAssistant/product`, {
      params: { commercialPlazaId: currentMarketId.value, size: 1000 }
    })
    .then(res => {
      productList.value = res.list
    })
}
const storeList = ref([])
function getStoreList() {
  fetch
    .get(`/boom-center-product-service/sysAdmin/commercialPlazaRegion/page/${currentMarketId.value}`, {
      params: { size: 1000 }
    })
    .then(res => {
      storeList.value = res.records
    })
}
// function onLinkTypeChange(val) {
//   pagination.page = 1
//   getList()
// }

// 获取列表
function getList() {
  getStoreList()
  getProductList()
  // if (editData.value.linkType === 'STORE') {
  //   getStoreList()
  // } else if (editData.value.linkType === 'PRODUCT') {
  //   getProductList()
  // }
}
const currentPickLinkId = ref('')
function handleClickPickerItem(id) {
  if (currentPickLinkId.value !== id) {
    currentPickLinkId.value = id
  } else {
    currentPickLinkId.value = ''
  }
}
function handleSubmitPick() {
  if (currentPickLinkId.value) {
    editData.value.linkId = currentPickLinkId.value
  }
  isShowJumpPicker.value = false
}

const bannerList = ref([])
const isShowEditDialog = ref(false)
const currentEditIndex = ref(0)
const editData = ref({
  id: '',
  imageUrl: '',
  linkType: 'PRODUCT',
  linkId: '',
  hideStatus: 1,
  expirationTime: ''
})

function handlePickImage() {
  pickImageList({ count: 1 }).then(res => {
    if (res[0]) {
      editData.value.imageUrl = res[0]
    }
  })
}
function handleAdd() {
  editData.value = {
    id: '',
    imageUrl: '',
    linkType: 'PRODUCT',
    linkId: '',
    hideStatus: 1,
    expirationTime: ''
  }
  bannerList.value.push({ ...editData.value })
  currentEditIndex.value = bannerList.value.length - 1
  isShowEditDialog.value = true
}
function handleEdit(index) {
  currentEditIndex.value = index
  editData.value = {
    ...bannerList.value[index],
    expirationTime: new Date(bannerList.value[index]?.expirationTime || undefined)
  }
  isShowEditDialog.value = true
  // onLinkTypeChange(editData.value.linkType)
}
const errorMessageForEdit = computed(() => {
  return ''
})
function handleSubmitEdit() {
  bannerList.value.splice(currentEditIndex.value, 1, { ...editData.value })
  isShowEditDialog.value = false
  emits('input', bannerList.value)
}
function handleDelete(index) {
  bannerList.value.splice(index, 1)
  emits('input', bannerList.value)
}
function handleMoveLeft(n) {
  bannerList.value.splice(n - 1, 0, bannerList.value.splice(n, 1)[0])
  emits('input', bannerList.value)
}
function handleMoveRight(n) {
  bannerList.value.splice(n, 0, bannerList.value.splice(n + 1, 1)[0])
  emits('input', bannerList.value)
}

const raw = ref({})
function getData() {
  return fetch.get(`/boom-center-product-service/sysAdmin/commercialPlaza/${currentMarketId.value}`).then(res => {
    bannerList.value = res.commercialPlazaBannerList
    raw.value = res
    raw.value.businessDistrictIds = res.businessDistrictVOs.map(b => {
      if (b.parent) return [b.parent.id, b.id]
      return [b.id]
    })
    return
  })
}

const validateErrorMessage = computed(() => {
  return ''
})
function handleSubmit() {
  if (validateErrorMessage.value) {
    message.warning(validateErrorMessage.value)
  } else {
    isAudit.value = true
    fetch
      .post(
        `/boom-center-product-service/businessAdmin/commercialPlazaAssistant/bannerEdit/${currentMarketId.value}`,
        raw.value.commercialPlazaBannerList.map(v => {
          return {
            ...v,
            expirationTime: new Date(v.expirationTime)?.getTime() || 0
          }
        })
      )
      .then(res => {
        isAudit.value = false
        message.success('保存成功，等待审核')
        router.go(-1)
      })
      .catch(err => {
        isAudit.value = false
        message.error(err?.msg || err)
      })
  }
}

onMounted(() => {
  getData()
  getList()
})
</script>

<style lang="less" scoped>
.cover {
  z-index: 9999;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.2);
}
.bannerItem {
  .trans;
  width: 345px;
  height: 88px;
  border: 1px solid;
  border-radius: 5px;
  border-color: #ccc;
  margin: 0 5px 5px 0;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  position: relative;
  &:hover {
    border-color: var(--color-primary);
  }
}
.componentContainer {
  display: flex;
  flex-wrap: wrap;
  .item {
    .bannerItem();
    .actionBar {
      position: absolute;
      left: 0;
      right: 0;
      bottom: 0;
      height: 30px;
      display: flex;
      align-items: stretch;
      background: rgba(0, 0, 0, 0.45);
      color: white;
      .btn {
        flex-grow: 1;
        line-height: 30px;
        text-align: center;
        border-right: 1px solid var(--color-border);
        &:last-child {
          border-right: none;
        }
        &:hover {
          background: var(--color-primary);
        }
      }
    }
  }
  .addButton {
    border-color: var(--color-green);
    color: var(--color-green);
    i {
      font-size: 60px;
    }
  }
}
.jumpTargetInfo {
  border: 1px solid var(--color-border);
  position: relative;
  width: 100%;
  max-width: 500px;
  overflow: hidden;
  padding-left: 60px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  line-height: 1.5;
  .type {
    background: var(--color-primary);
    color: white;
    position: absolute;
    top: -5px;
    right: -5px;
    padding: 5px 10px;
    border-radius: 0 0 0 50px;
  }
  .logo {
    width: 40px;
    height: 40px;
    position: absolute;
    left: 10px;
    top: 10px;
  }
}
.storeList,
.productList {
  width: 100%;
  .storeItem,
  .productItem {
    .trans;
    display: flex;
    align-items: center;
    border-bottom: 1px solid var(--color-border);
    line-height: 1.2em;
    padding-bottom: 5px;
    margin-bottom: 5px;
    cursor: pointer;
    &.isActive {
      background: var(--color-primary);
      color: white;
    }
    .logo {
      --size: 60px;
      width: var(--size);
      height: var(--size);
      margin: 10px;
    }
    .info {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      font-size: 12px;
    }
  }
}
</style>
